ul.todo-list {
  list-style: none;
  padding: 0;
  margin: 0;
  border-top: 1px solid var(--gold);
  height: 55vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
li {
  padding: 1rem 2rem;
  border-bottom: 1px solid var(--gold);
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 1rem;
}
li span.text {
  flex-grow: 1;
  display: flex;
  gap: 0.5rem;
  flex-wrap: nowrap;
  align-items: center;
}
input[type="text"] {
  border: 0;
  flex-grow: 1;
  background: transparent;
}
li.completed input[type="text"] {
  text-decoration: line-through;
  color: var(--light-gray);
}
button.delete-task {
  background: transparent;
  display: flex;
  align-items: center;
}
button svg {
  width: 20px;
  height: 1rem;
}
